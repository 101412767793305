<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('faq.title') }}</span>
		</div>

		<v-divider class="my-2" />

		<FAQCard v-for="(faq, index) in items" :key="index" :faq="faq" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('nav.faq'),
			meta: [{ name: 'description', content: 'Frequently Asked Questions' }],
		}
	},
	components: {
		FAQCard: () => import('@/components/main/FAQCard.vue'),
	},
	computed: {
		items() {
			return [
				{ question: this.$t('faq.student.question01'), answer: this.$t('faq.student.answer01') },
				{ question: this.$t('faq.student.question02'), answer: this.$t('faq.student.answer02') },
				{ question: this.$t('faq.student.question03'), answer: this.$t('faq.student.answer03') },
				{ question: this.$t('faq.student.question04'), answer: this.$t('faq.student.answer04') },
				{ question: this.$t('faq.student.question05'), answer: this.$t('faq.student.answer05') },
				{ question: this.$t('faq.student.question06'), answer: this.$t('faq.student.answer06') },
				{ question: this.$t('faq.student.question07'), answer: this.$t('faq.student.answer07') },
				{ question: this.$t('faq.student.question08'), answer: this.$t('faq.student.answer08') },
				{ question: this.$t('faq.student.question09'), answer: this.$t('faq.student.answer09') },
				{ question: this.$t('faq.student.question10'), answer: this.$t('faq.student.answer10') },
				{ question: this.$t('faq.student.question11'), answer: this.$t('faq.student.answer11') },
				{ question: this.$t('faq.student.question12'), answer: this.$t('faq.student.answer12') },
			]
		},
	},
}
</script>
